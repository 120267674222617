<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      height="302px"
      :close-on-click-modal="false"
      class="dialog"
    >
      <div class="wrapper">
        <div class="icon el-icon-s-opportunity icon-bulb"></div>
        <el-form ref="form" label-position="right" :model="form" class="content" label-width="80px">
          <div class="title">安全校验</div>
          <div class="text">为了保证数据安全，请输入校验码查看报告。</div>
          <el-form-item label="校验码:">
            <el-input v-model="form.shareCode" placeholder="请输入内容"></el-input>
            <div class="error" v-if="error">{{ error }}</div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <button @click="handleConfirmClick" class="btn btn-ok">查看报告</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { checkShareCode } from "@/api/api";
import { LocalStorage } from "ttl-localstorage";

export default {
  name: "InputShareCodeDialog2",
  props: {
    uuid: String,
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        shareCode: "",
      },
      error: false,
    };
  },
  mounted() {},
  computed: {
    isMobileDevice() {
      console.log(window.innerWidth);
      return window.innerWidth < 600;
    },
    width() {
      if (this.isMobileDevice) {
        return "90%";
      } else {
        return "600px";
      }
    },
  },
  methods: {
    async handleConfirmClick() {
      const res = await checkShareCode(this.uuid, this.form.shareCode);

      if (res.msgCode == 1) {
        if (res.data.result) {
          const timeout = Math.round((res.data.effectiveDate - Date.now()) / 1000);
          LocalStorage.put(`${this.$route.params.checkId}-${this.uuid}-access`, 1, timeout);
          if (res.data.token) {
            localStorage.setItem("Share-Authorization", res.data.token);
          }
          // location.reload();
          window.location.reload();
          this.dialogVisible = false;
        } else {
          this.error = res.data.cause;
        }
      } else {
        this.error = "未知错误";
      }
      // const res = await checkShareCode(
      //   this.uuid,
      //   this.form.shareCode,
      //   this.$store.state.authorization
      // );

      // if (res.msgCode == 1) {
      //   if (res.data.result) {
      //     this.$router.push({
      //       name: "result-head-share2",
      //       params: {
      //         ...this.$route.params,
      //         shareCode: this.form.shareCode,
      //       },
      //       query: {
      //         ...this.$route.query,
      //       },
      //     });
      //     this.dialogVisible = false;
      //   } else {
      //     this.error = res.data.cause;
      //   }
      // } else {
      //   this.error = "未知错误";
      // }
    },
  },
};
</script>
<style lang="stylus" scoped>
.wrapper {
  display: flex;
  width 100%

  .icon {
    color: rgb(250, 173, 20);
    font-size: 32px;
  }

  .content {
    margin-left: 16px;

    .title {
      font-weight: 600;
      color: rgba(0, 0, 0, 0.65);
      line-height: 22px;
      font-size: 16px;
    }

    .text {
      margin-top: 8px;
      font-size: 14px;
      color: #666;
      line-height: 1.5;
      margin-bottom 32px
    }

    .error {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #C30505;
      line-height: 20px;
      margin-top 16px
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding 0
  margin 0;

  .btn-ok {
    width 100%
    flex-grow: 0;
    font-size: 14px;
    font-weight: 600;
    color: #FFFFFF;
    margin 0
    border-radius 0
    line-height: 68px;
    padding 0
    background: #2F54EB;
  }
}
>>> .el-dialog{
     width 500px;
}
>>> .el-dialog__header {
  padding: 0;
}

>>> .el-dialog__body {
  margin-bottom 0;
  padding-bottom 10px;
}

>>> .el-dialog__footer {
  padding 0
}


@media screen and (max-width: 800px) {
  >>> .el-dialog{
    width 95%;
  }
}
@media screen and (max-width: 400px) {
  .icon-bulb{
    display none
  }
  >>> .el-form-item__label{
    position relative;
    top -2px
  }
}
</style>
